

















import { Component, Mixins, Vue } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentCurrentObjective from '@/components/organisms/StudentCurrentObjective.vue'
import StudentCurrentObjectiveEntrance from '@/components/organisms/StudentCurrentObjectiveEntrance.vue'
import StudentPreviousObjectives from '@/components/organisms/StudentPreviousObjectives.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    StudentCurrentObjective,
    StudentCurrentObjectiveEntrance,
    StudentPreviousObjectives,
    ButtonBase,
  },
})
export default class GoalList extends Mixins(ClassModeMethods, ClassModeChangeAble) {
  private classModeTextByClassMode = ''

  private async mounted(): Promise<void> {
    await this.checkClassModeAndSideMenuMode()
    this.classModeTextByClassMode = this.classModeText(this.currentClassMode)
  }
}
