



























import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { EntranceChoiceSchool } from '@/models/api/studentGoal'
import LocalMoment from '../atoms/LocalMoment.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    ButtonBase,
  },
})
export default class StudentCurrentObjectiveEntrance extends Mixins(LocalMoment, ClassModeChangeAble) {
  // 現在の目標
  private currentGoal: EntranceChoiceSchool | null = null

  private get formatTestDay() {
    if (!this.currentGoal || !this.currentGoal?.testDay) return ''

    return this.reformatDate(this.currentGoal.testDay, 'YYYY-MM-DD', 'YYYY年MM月DD日')
  }

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode()
    await this.loadCurrentGoal()
    Vue.prototype.$loading.complete()
  }

  /**
   * 現在の目標取得
   */
  private async loadCurrentGoal() {
    const { data }: { data: EntranceChoiceSchool } = await Vue.prototype.$http.httpWithToken.get(
      '/studentGoals/current',
      {
        params: { classModeCode: 'NY' },
      }
    )
    // 現在の目標取得
    this.currentGoal = data
  }

  private moveToSetting() {
    let path = '/student/goal-setting-entrance'
    // 現在の目標が存在する場合、目標IDを渡す
    if (this.currentGoal) path = path + `/${this.currentGoal.id}`
    this.$router.push(path)
  }
}
