









































import { Component, Mixins, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { StudentGoal, Targetable } from '@/models/api/studentGoal'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import LocalMoment from '../atoms/LocalMoment.vue'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    ButtonBase,
  },
})
export default class StudentCurrentObjective extends Mixins(ClassModeMethods, LocalMoment, ClassModeChangeAble) {
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  // 現在の目標
  private currentGoal: StudentGoal | null = null

  // 定期テスト/講習会の場合、期間中の定期テスト/講習会情報を取得する
  private targetable: Targetable | null = null

  private get isCreateAbleNewGoal() {
    let isAble = this.currentGoal == null
    if (this.classMode() == this.CLASS_MODE.TEST || this.classMode() == this.CLASS_MODE.SEMINAR) {
      isAble = isAble && this.targetable != null
    }
    return isAble
  }

  // 現在の目標作成日が本日中の場合は編集可能。翌日以降は完了ボタンを表示する。
  private get isEditablePeriod() {
    return this.currentGoal != null && this.currentGoal.from == this.today().format('YYYY-MM-DD')
  }

  private get nameWithMode() {
    return `${this.classModeText(this.classMode())}モード: ${this.currentGoal?.name}`
  }

  private get achievementText() {
    const achievedLevel = this.currentGoal?.achievedLevel || '0/5'
    return `${achievedLevel}教科達成`
  }

  private async mounted(): Promise<void> {
    await this.checkClassModeAndSideMenuMode()
    this.loadDatas()
  }

  private async loadDatas() {
    Vue.prototype.$loading.start()
    await this.loadCurrentGoal()
    await this.loadCurrentTargetable()
    Vue.prototype.$loading.complete()
  }

  /**
   * 現在の目標取得
   */
  private async loadCurrentGoal() {
    const { data }: { data: StudentGoal } = await Vue.prototype.$http.httpWithToken.get('/studentGoals/current', {
      params: { classModeCode: this.classMode() },
    })
    // 現在の目標取得
    this.currentGoal = data
  }

  private async loadCurrentTargetable() {
    if (![this.CLASS_MODE.TEST, this.CLASS_MODE.SEMINAR].includes(this.classMode())) return

    const { data }: { data: Targetable } = await Vue.prototype.$http.httpWithToken.get(
      '/studentGoals/currentTargetable',
      {
        params: { classModeCode: this.classMode() },
      }
    )
    // 期間中の定期テスト/講習会
    this.targetable = data
  }

  private moveToSetting() {
    let path = '/student/goal-setting'
    // 現在の目標が存在する場合、目標IDを渡す
    if (this.currentGoal) path = path + `/${this.currentGoal.id}`
    this.$router.push(path)
  }

  /**
   * 現在の目標を完了する (終了日を前日へ変更する)
   */
  private async suspendCurrentGoal() {
    if (!confirm('現在の目標を完了しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .post('/studentGoals/suspendCurrentGoal', { classModeCode: this.classMode() })
      .then(() => {
        alert('目標を完了しました')
        location.reload()
      })
  }
}
